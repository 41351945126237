<template>
  <v-card color="primary" class="ma-auto" outlined width="500">
    <v-card-title class="white--text">
      <span @click="$store.commit('toggleExpand', 'login')">User Login</span>
      <v-spacer></v-spacer>
      <v-btn color="white" icon @click="$store.commit('toggleExpand', 'login')">
        <v-icon>{{
          $store.getters.isExpanded("login")
            ? "mdi-chevron-up"
            : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
    </v-card-title>

    <v-expand-transition>
      <div v-show="$store.getters.isExpanded('login')">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <template v-if="!$store.getters['user/isLoggedIn']">
                To manually control the interlock, please log in:
                <v-card-text>
                  <v-form ref="loginform" v-model="isValid" lazy-validation>
                    <v-text-field
                      label="Login"
                      v-model="username"
                      prepend-icon="mdi-account"
                      type="text"
                      required
                      :rules="[(v) => !!v || 'Username is required']"
                    ></v-text-field>
                    <v-text-field
                      id="password"
                      label="Password"
                      v-model="password"
                      prepend-icon="mdi-lock"
                      type="password"
                      required
                      :rules="[(v) => !!v || 'Password is required']"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="secondary" @click.stop="userLogin">
                    <v-progress-circular
                      v-if="login_busy"
                      indeterminate
                      color="white"
                    ></v-progress-circular
                    >Login
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click.stop="guestLogin">
                    <v-progress-circular
                      v-if="login_busy"
                      indeterminate
                      color="white"
                    ></v-progress-circular
                    >Guest
                  </v-btn>
                </v-card-actions>
                <v-card-text
                  >If you forgot your password, contact MIT.nano for
                  help.</v-card-text
                >
              </template>
              <template v-else>
                You are logged in as {{ $store.getters["user/username"] }}
                <v-card-actions>
                  <v-btn
                    color="primary"
                    v-if="$store.getters['user/isLoggedIn']"
                    @click.stop="$router.push('/')"
                  >
                    Main Page
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    v-if="$store.getters['user/isLoggedIn']"
                    @click.stop="userLogout"
                  >
                    <v-progress-circular
                      v-if="logout_busy"
                      indeterminate
                      color="white"
                    ></v-progress-circular
                    >Logout
                  </v-btn>
                </v-card-actions>
              </template>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    username: null,
    password: null,
    login_busy: false,
    logout_busy: false,
    blockActions: false,
    isValid: null,
    emailRules: [(v) => !v || /.+@.+\..+/.test(v) || "E-mail must be valid"],
  }),

  methods: {
    async guestLogin() {
      var newLocation = "/";
      try {
        // block the form from multiple submissions and start the busy spinner
        if (this.blockActions) return;
        this.blockActions = true;
        this.login_busy = true;
        // try to login
        const result = await this.$store.dispatch("user/login", {
          username: "guest",
          password: "guest",
        });
        console.log("login done");
        console.log(result);
        if (!result) {
          // if login was successful, nothing else to do, all user states are being set by dispatch. otherwise note error:
          var errMsg = this.getErrorMessage(result.reason, result.message);
          var aMessage = [];
          aMessage.push({ textbr: "Login error." });
          aMessage.push({ textbr: errMsg.text });
          newLocation = errMsg.location;
          await this.$root.$confirmdialog("Login", aMessage, {
            color: "primary",
            reject: "",
            accept: "Okay",
          });
        }
      } catch (e) {
        newLocation = "/";
        console.log(e);
        console.log("Login Error");
      }
      // clear the locks and the form
      this.blockActions = false;
      this.login_busy = false;
      this.password = null;
      if (newLocation != "") this.$router.push(newLocation);
    },
    async userLogin() {
      // validate form
      await this.$refs.loginform.validate();
      if (!this.isValid) return;
      // try to log in. new location will be the new router place, if not blank
      var newLocation = "/";
      try {
        // block the form from multiple submissions and start the busy spinner
        if (this.blockActions) return;
        this.blockActions = true;
        this.login_busy = true;
        // try to login
        const result = await this.$store.dispatch("user/login", {
          username: this.username,
          password: this.password,
        });
        if (!result) {
          // if login was successful, nothing else to do, all user states are being set by dispatch. otherwise note error:
          var errMsg = this.getErrorMessage(result.reason, result.message);
          var aMessage = [];
          aMessage.push({ textbr: "Login error." });
          aMessage.push({ textbr: errMsg.text });
          newLocation = errMsg.location;
          await this.$root.$confirmdialog("Login", aMessage, {
            color: "primary",
            reject: "",
            accept: "Okay",
          });
        }
      } catch (e) {
        newLocation = "/";
        console.log(e);
        console.log("Login Error");
      }
      console.log("reloading data...");
      await this.$store.dispatch("data/refreshAll");
      // clear the locks and the form
      this.blockActions = false;
      this.login_busy = false;
      this.password = null;
      console.log("routing...");
      if (newLocation != "") this.$router.push(newLocation);
    },
    async userLogout() {
      try {
        if (this.blockActions) return;
        this.blockActions = true;
        this.logout_busy = true;
        await this.$store.dispatch("user/logout");
        this.password = null;
        this.username = null;
      } catch (e) {
        console.log(e);
        console.log("Error");
      }
      this.blockActions = false;
      this.logout_busy = false;
    },
    // Helpers
    getErrorMessage(reasonCode, defaultMessage) {
      if (reasonCode == "logincount") {
        return {
          text: "Too many failed login attempts. Please wait 1 hour before trying again.",
          location: "/",
        };
      } else if (reasonCode == "awaitingemail") {
        return {
          text: "Account is pending verification, check your kerberos (MIT) email for instructions.",
          location: "/",
        };
      } else if (reasonCode == "passwordreset") {
        return { text: "Password needs to be reset.", location: "" };
      } else if (reasonCode == "tokenfailed") {
        return { text: "The password code was incorrect.", location: "" };
      } else if (reasonCode == "suspension") {
        return {
          text: "Account was suspended. Please contact MIT.nano staff for details.",
          location: "/",
        };
      }
      return { text: defaultMessage, location: "" };
    },
  },
};
</script>